var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('h1',{staticClass:"headline white--text"},[_c('v-icon',{staticClass:"ml-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"green","title":"päivitä"},on:{"click":function($event){_vm.ts = Date()}}},[_vm._v(" mdi-refresh-circle ")]),_vm._v(" Audio-aliakset ")],1),_c('aliases-table',{attrs:{"sort":"id","api_route":"audio_aliases","aliases_type":"audio_aliases","show_expand":true,"refresh_models":_vm.ts,"headers_prop":[
            {
              text: 'Nimi',
              align: 'start',
              sortable: true,
              value: 'alias_name',
            },
            { text: 'Kuva', value: 'audio_alias_media', sortable: false },
            { text: 'Domainit', value: 'domains', sortable: false },
            { text: 'Agent', value: 'agent', sortable: true },
          ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }