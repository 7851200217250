<template>
  <span>
        <!-- Heading -->
    <h1 class="headline white--text">
      <v-icon
          color="green"
          title="päivitä"
          class="ml-2"
          style="cursor: pointer"
          @click="ts = Date()"
      >
        mdi-refresh-circle
      </v-icon>
      Audio-aliakset
    </h1>

    <aliases-table
        sort="id"
        api_route="audio_aliases"
        aliases_type="audio_aliases"
        :show_expand="true"
        :refresh_models="ts"
        :headers_prop="[
              {
                text: 'Nimi',
                align: 'start',
                sortable: true,
                value: 'alias_name',
              },
              { text: 'Kuva', value: 'audio_alias_media', sortable: false },
              { text: 'Domainit', value: 'domains', sortable: false },
              { text: 'Agent', value: 'agent', sortable: true },
            ]"
    ></aliases-table>

  </span>
</template>

<script>
// @ is an alias to /srcm
import GlobalVars from "../mixins/GlobalVars";
import AliasesTable from "../components/AliasesTable";

export default {
  name: 'AudioAliases',
  mixins: [GlobalVars], //import default set of values
  data() {
    return {
      models: [],
      order: 'id',
      order_direction: 'asc',
      ts: '',
    }
  },
  components: {AliasesTable},

}
</script>
